import { useReCaptcha } from 'vue-recaptcha-v3'

export function useRecaptcha() {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

  return async (action) => {
    await recaptchaLoaded()
    return await executeRecaptcha(action)
  }
}
